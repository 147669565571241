import React, { CSSProperties } from "react";

import { IArtistProps, LinkClass, BURGER_COLOR } from "../../../types";
import CustomLink from "../../common/CustomLink";

const Web = ({ links, symbol, blue, id }: IArtistProps) => {
  const web = links!.web!;
  const svg = blue
    ? require(`../../../static/symbols/white/${symbol}`)
    : require(`../../../static/symbols/blue/${symbol}`);
  const style: CSSProperties = {
    position: "relative",
    width: "100%",
    fontSize: "1em",
  };
  const logoStyle: CSSProperties = {
    height: `${id === 13 ? 0.4 : 1}em`,
    verticalAlign: "middle",
    margin: "0 0 0.15em 0",
    paddingRight: "0.75em",
  };
  const linkStyle: CSSProperties = {
    paddingLeft: "0.3em",
  };

  return (
    <div className="website-container" style={style}>
      <img src={svg} alt={symbol} style={logoStyle} />

      <CustomLink
        style={linkStyle}
        name="website-link"
        body="웹사이트"
        href={web}
        color={blue ? BURGER_COLOR.WHITE : BURGER_COLOR.BLUE}
        type={LinkClass.A}
      />
    </div>
  );
};

export default Web;
