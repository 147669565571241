import React, { useState } from "react";
import { connect } from "react-redux";
import Loading from "./Loading";
import Background from "../common/Background";
import useFrameSize from "../../hooks/useFrameSize";

export const Mix = () => {
  const [width, height] = useFrameSize();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  if (shouldRedirect) {
    return <Loading />;
  }

  return (
    <>
      <Background />
      <div
        className="mix-contain"
        style={{
          position: "fixed",
          top: "25%",
          left: 0,
          width: "100%",
          height: "50%",
          fontSize: width < height ? "18vh" : "20vh",
          display: "table",
          mixBlendMode: "exclusion",
        }}
      >
        <p
          style={{
            textAlign: "center",
            display: "table-cell",
            verticalAlign: "middle",
            fontSize: "inherit",
            color: "#FFFF00",
          }}
          onClick={() => setShouldRedirect(true)}
        >
          믹스!
        </p>
      </div>
      <div
        className="footer"
        style={{
          position: "fixed",
          bottom: width < height ? "2vh" : "1.5%",
          width: "100%",
          left: 0,
          mixBlendMode: "exclusion",
          fontSize: "2.5vh",
        }}
      >
        <p
          style={{
            textAlign: "center",
            color: "#FFFF00",
            wordBreak: "keep-all",
          }}
        >
          <span style={{ whiteSpace: "nowrap" }}>
            당신은 어떤 네오-트라이브
            <wbr />
            일까요?
          </span>{" "}
          <span style={{ whiteSpace: "nowrap" }}>화면을 눌러 알아보세요.</span>
        </p>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Mix);
