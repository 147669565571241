import React, { CSSProperties } from "react";

import { IDeviceProps } from "../../types";

const TimeLocation = ({ isVertical }: IDeviceProps) => {
  const style: CSSProperties = {
    position: "relative",
    display: "inline-block",
    wordBreak: "keep-all",
    width: isVertical ? "calc(100% - 4vh)" : "calc(21%)",
    padding: isVertical ? "0 2vh" : "0 1.5% 0 2.5%",
    verticalAlign: "top",
  };
  return (
    <div className="time-location-container" style={style}>
      <p>
        2020.
        <wbr />
        5.11(월)–
        <wbr />
        5.24(일)
      </p>
      <br />
      <p>
        <a
          href="http://neotribe2020.xyz"
          className="self-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span
            style={{ borderBottom: `solid ${isVertical ? 0.4 : 0.5}vh white` }}
          >
            neotribe
            <wbr />
            2020
            <wbr />
            .xyz
          </span>
        </a>
      </p>
      <br />
    </div>
  );
};

export default TimeLocation;
