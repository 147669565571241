import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import store from "../../redux/store";
import { updateHeader, changeBurgerColor } from "../../redux/actions";

import Artists from "./Artists";
import TimeLocation from "./TimeLocation";
import Leedotype from "./Leedotype";
import Sponsor from "./Sponsor";
import SponsoredBy from "./SponsoredBy";

import useFrameSize from "../../hooks/useFrameSize";
import { IState, BURGER_COLOR } from "../../types";

const Credits = ({ open }) => {
  const [width, height] = useFrameSize();
  const [isVertical, setIsVertical] = useState(width < height);
  useEffect(() => {
    setIsVertical(width < height);
  }, [width, height]);

  useEffect(() => {
    if (!open) {
      if (isVertical) {
        store.dispatch(updateHeader(["네오-트라이브2020"]));
      } else {
        store.dispatch(
          updateHeader(["네오-트라이브2020: 사회가 잠시 멀어졌을 때"])
        );
      }
      store.dispatch(changeBurgerColor(BURGER_COLOR.WHITE));
    }
  }, [isVertical, open]);

  return (
    <div
      className="credits-container"
      style={{
        backgroundColor: "#4400CC",
        color: "white",

        fontSize: isVertical ? "3vh" : "3vh",

        left: 0,
        top: 0,
        width: "100%",
        paddingTop: "15vh",
      }}
    >
      {isVertical ? (
        <>
          <div
            className="left-container"
            style={{
              display: "inline-block",
              margin: 0,
              width: "50%",
              verticalAlign: "top",
            }}
          >
            <Artists isVertical={isVertical} />
          </div>
          <div
            className="right-container"
            style={{
              display: "inline-block",
              margin: 0,
              width: "50%",
              verticalAlign: "top",
            }}
          >
            <TimeLocation isVertical={isVertical} />
            <Leedotype isVertical={isVertical} />
            <SponsoredBy isVertical={isVertical} />
          </div>
          <div
            className="bottom-container"
            style={{
              display: "block",
              margin: 0,
              width: "100%",
            }}
          >
            <Sponsor isVertical={isVertical} />
          </div>
        </>
      ) : (
        <>
          <Artists isVertical={isVertical} />
          <TimeLocation isVertical={isVertical} />
          <Leedotype isVertical={isVertical} />
          <Sponsor isVertical={isVertical} />
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: IState) => ({
  open: state.burgerOpen,
});

export default connect(mapStateToProps, {})(Credits);
