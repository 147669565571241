import React, { useState, useEffect, MouseEvent, useRef } from "react";
import { Redirect } from "react-router-dom";

import { IScreenPoint, ILookup } from "../../types";

import _ from "lodash";

interface ITribeProps {
  pos: IScreenPoint;
  artist: ILookup;
  ind: number;
  touch?: IScreenPoint;
}
const Tribe = ({ pos, artist, ind, touch }: ITribeProps) => {
  const divRef = useRef<HTMLDivElement>(null);

  const delay = 200;

  const symbol = require(`../../static/symbols/yellow/${artist.symbol}`);

  const numAlt = artist.hover;

  const [showAlt, setShowAlt] = useState(false);

  const [offset, setOffset] = useState<IScreenPoint>({ x: 0, y: 0 });

  const [altInd, setAltInd] = useState(0);

  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    setOffset({
      x: _.random(2, 6.1) * (1 - 2 * _.random(0, 1)),

      y: _.random(2, 6.1) * (1 - 2 * _.random(0, 1)),
    });
  }, []);

  useEffect(() => {
    if (touch && divRef.current) {
      const { x, y } = touch;
      const { top, bottom, left, right, width, height } =
        divRef.current.getBoundingClientRect();
      const w = 1 * width;
      const h = 1 * height;
      if (x >= left - w && x <= right + w && y >= top - h && y <= bottom + h) {
        setShowAlt(true);
      } else {
        setShowAlt(false);
      }
    }
  }, [touch]);

  const handleMouseEnter = (e: MouseEvent) => {
    setShowAlt(true);
  };
  const handleMouseLeave = (e: MouseEvent) => {
    setTimeout(() => setAltInd(_.random(numAlt - 1)), delay);
    setShowAlt(false);
  };

  if (shouldRedirect) {
    return <Redirect to={`/artist/${ind}`} />;
  }

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => setShouldRedirect(true)}
      style={{
        position: "fixed",
        top: `calc(${pos.y - offset.y}% - 3vh)`,
        left: `calc(${pos.x - offset.x}% - 3vh)`,

        height: "6vh",
        width: "6vh",
        mixBlendMode: "difference",
      }}
      ref={divRef}
    >
      <img
        src={require(`../../static/hover/${ind}/${altInd}.svg`)}
        alt="letter"
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          objectFit: "contain",
          objectPosition: "center center",
          opacity: showAlt ? 1 : 0,
          transition: `opacity ${delay}ms ease-in`,
        }}
      />

      <img
        src={symbol}
        alt={artist.symbol}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          objectFit: "contain",
          objectPosition: "center center",
          opacity: showAlt ? 0 : 1,
          transition: `opacity ${delay}ms ease-in`,
        }}
      />
    </div>
  );
};

export default Tribe;
