import React, { CSSProperties, useState, useEffect } from "react";

import { IArtistProps, BURGER_COLOR, LinkClass } from "../../../types";
import CustomLink from "../../common/CustomLink";

const Marketplace = ({ links, symbol, blue, id }: IArtistProps) => {
  const [showChildren, setShowChildren] = useState(false);
  useEffect(() => {
    setShowChildren(false);
  }, [id]);

  const marketplace = links.marketplace!;
  const svg = blue
    ? require(`../../../static/symbols/white/${symbol}`)
    : require(`../../../static/symbols/blue/${symbol}`);
  const style: CSSProperties = {
    position: "relative",
    width: "100%",
    fontSize: "1em",
  };
  const logoStyle: CSSProperties = {
    height: `${id === 13 ? 0.4 : 1}em`,
    verticalAlign: "middle",
    margin: "0 0 0.15em 0",
    paddingRight: "1.05em",
  };
  const linkStyle: CSSProperties = {
    //paddingLeft: "0.3em",
  };

  const children =
    marketplace.length > 1
      ? marketplace.map((item, ind) => (
          <div
            className="children"
            key={ind}
            style={{
              paddingLeft: "2em",
            }}
          >
            <table cellPadding="0" cellSpacing="0">
              <tr>
                <td style={{ verticalAlign: "top" }}>
                  <div
                    className="logo-container-in-table"
                    style={{
                      verticalAlign: "top",
                      left: 0,
                      top: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img src={svg} alt={symbol} style={logoStyle} />
                  </div>
                </td>
                <td>
                  <CustomLink
                    style={linkStyle}
                    name="marketplace-link"
                    body={item.name}
                    href={item.link}
                    color={blue ? BURGER_COLOR.WHITE : BURGER_COLOR.BLUE}
                    type={LinkClass.A}
                  />
                </td>
              </tr>
            </table>
          </div>
        ))
      : null;

  const parent = (
    <div className="marketplace-container" style={style}>
      <table cellPadding="0" cellSpacing="0">
        <tr>
          <td style={{ verticalAlign: "top" }}>
            <div
              className="logo-container-in-table"
              style={{
                verticalAlign: "top",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
              }}
            >
              <img src={svg} alt={symbol} style={logoStyle} />
            </div>
          </td>
          <td>
            {children ? (
              <>
                <CustomLink
                  name="marketplace-toggle"
                  style={linkStyle}
                  body={marketplace[0].desc}
                  onClick={() => setShowChildren((state) => !state)}
                  type={LinkClass.SPAN}
                  color={blue ? BURGER_COLOR.WHITE : BURGER_COLOR.BLUE}
                />

                <div
                  className="marketplace-children-container"
                  style={{
                    opacity: showChildren ? 1 : 0,
                    height: showChildren ? `${children.length * 1.57}em` : 0,
                    transform: `translateY(${showChildren ? "0" : "-100%"})`,
                    transition: `transform 200ms ease-in-out,opacity 100ms ease-in-out ${
                      showChildren ? "100ms" : ""
                    },height 200ms ease-in-out`,
                  }}
                >
                  {children}
                </div>
              </>
            ) : (
              <CustomLink
                style={linkStyle}
                name="marketplace-link"
                body={`${marketplace[0].desc}${
                  marketplace[0].name ? "(" + marketplace[0].name + ")" : ""
                }`}
                href={marketplace[0]!.link}
                color={blue ? BURGER_COLOR.WHITE : BURGER_COLOR.BLUE}
                type={LinkClass.A}
              />
            )}
          </td>
        </tr>
      </table>
    </div>
  );

  return parent;
};

export default Marketplace;
