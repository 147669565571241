import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import useFrameSize from "../../hooks/useFrameSize";
import { IState, BURGER_COLOR, LinkClass } from "../../types";

import Background from "../common/Background";

import CustomLink from "../common/CustomLink";

import store from "../../redux/store";
import { updateHeader, changeBurgerColor } from "../../redux/actions";
import { Mix } from "./Mix";
import Loading from "./Loading";
import useThemeColor from "../../hooks/useThemeColor";

interface INeomixProps {
  color: BURGER_COLOR;
  match?: any;
  open: boolean;
}

export const Neomix = ({ open, color, match }: INeomixProps) => {
  const [width, height] = useFrameSize();
  const [again, setAgain] = useState(false);

  const id = match?.params.id;

  const [display, setDisplay] = useState(
    require(`../../static/neomix/yellow/0.png`)
  );
  const [download, setDownload] = useState(
    require(`../../static/neomix/share/0.png`)
  );
  useEffect(() => {
    setDisplay(require(`../../static/neomix/yellow/${id ?? 0}.png`));
    setDownload(require(`../../static/neomix/share/${id ?? 0}.png`));
    setAgain(false);
  }, [id]);

  useThemeColor(open ? "#4400CC" : "#6D3082");

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    if (!open) {
      store.dispatch(
        updateHeader(
          width < height
            ? ["네오-트라이브2020"]
            : ["네오-트라이브2020: 사회가 잠시 멀어졌을 때"]
        )
      );
      store.dispatch(changeBurgerColor(BURGER_COLOR.YELLOW));
    }
  }, [open, width, height]);

  if (id === undefined) {
    return <Mix />;
  }
  if (again) {
    return <Loading id={id} />;
  }
  return (
    <>
      <Background />
      <div
        className="neomix-container"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          margin: `${width < height ? 0 : "5vh"}`,
          width: `calc(${width}px - ${width < height ? "0px" : "10vh"})`,
          height: `calc(${height}px - ${width < height ? "0px" : "10vh"})`,
          mixBlendMode: "exclusion",
          zIndex: 10,
          animation: "fade-in 1.5s ease-in-out",
        }}
      >
        <img
          src={display}
          alt="neomix!"
          style={{
            width: "100%",
            height: "100%",
            objectPosition: "center center",
            objectFit: "contain",
            zIndex: 5,
          }}
        />
      </div>
      <div
        className="share-container"
        style={{
          bottom: 0,
          right: 0,
          marginBottom: height > width ? "2vh" : "1.5%",
          marginRight: height > width ? "2vh" : "2%",
          position: "fixed",
          mixBlendMode: color === BURGER_COLOR.YELLOW ? "exclusion" : undefined,

          lineHeight: 1.57,
          fontSize: "3vh",
          zIndex: 11,
          animation: "fade-in 2s ease-in-out",

          textAlign: "right",
        }}
      >
        <p>
          <CustomLink
            name="another-tribe"
            body="다시하기"
            color={BURGER_COLOR.YELLOW}
            type={LinkClass.SPAN}
            onClick={() => setAgain(true)}
          />
        </p>
        <p>
          <CustomLink
            name="download-link"
            body="저장하기"
            href={download}
            color={BURGER_COLOR.YELLOW}
            type={LinkClass.DOWNLOAD}
          />
        </p>
        <p
          className="fb-share-button"
          data-href={`http://neotribe2020.xyz/neomix/${id}`}
          data-layout="button"
          data-size="small"
        >
          <CustomLink
            name="facebook"
            body="페이스북"
            href={`https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Fneotribe2020.xyz%2Fneomix%2F${id}%2F&amp;src=sdkpreparse`}
            color={BURGER_COLOR.YELLOW}
            type={LinkClass.A}
          />
        </p>
        <p className="twitter-button">
          <CustomLink
            name="twitter"
            body="트위터"
            href={`https://twitter.com/intent/tweet?text=네오-트라이브2020%20온라인%20글꼴%20전시를%20다녀왔어요%21&url=http%3A%2F%2Fneotribe2020.xyz%2fneomix%2f${id}`}
            color={BURGER_COLOR.YELLOW}
            type={LinkClass.A}
          />
        </p>
      </div>
    </>
  );
};

const mapStateToProps = (state: IState) => ({
  color: state.burgerColor,
  open: state.burgerOpen,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Neomix);
