import React, { CSSProperties, useState, useEffect } from "react";

import { connect } from "react-redux";

import useFrameSize from "../../hooks/useFrameSize";

import { changeBurgerColor } from "../../redux/actions";
import { IState, BURGER_COLOR } from "../../types";

import store from "../../redux/store";

const initialStyle: CSSProperties = {
  backgroundSize: "cover",
  backgroundPosition: "center center",
  position: "fixed",
  top: 0,
  height: "100%",

  left: 0,
  width: "100%",
};

const Background = ({ open }) => {
  const [width, height] = useFrameSize();
  useEffect(() => {
    if (!open) {
      store.dispatch(changeBurgerColor(BURGER_COLOR.YELLOW));
    }
  }, [open]);

  const [style, setStyle] = useState<CSSProperties>(initialStyle);

  useEffect(() => {
    const src =
      height < width
        ? require("../../static/images/background-landscape.jpg")
        : require("../../static/images/background-portrait.jpg");
    setStyle((style) => ({
      ...style,
      backgroundImage: `url(${src})`,
    }));
  }, [width, height]);

  return <div className="background" style={style}></div>;
};

export default connect((state: IState) => ({ open: state.burgerOpen }), {
  changeBurgerColor,
})(Background);
