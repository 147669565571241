import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";

import _ from "lodash";
import { Redirect } from "react-router-dom";

import Background from "../common/Background";
import useFrameSize from "../../hooks/useFrameSize";

interface ILoadingProps {
  id?: number;
}
const paths = [
  "chj.svg",
  "hhw.svg",
  "hmj.svg",
  "jjh.svg",
  "jys.svg",
  "kka.svg",
  "kdk.svg",
  "klw.svg",
  "ktr.svg",
  "kyj.svg",
  "ljh.svg",
  "lne.svg",
  "ney.svg",
  "os.svg",
  "phs.svg",
  "skm.svg",
  "yhj.svg",
];
export const Loading = ({ id }: ILoadingProps) => {
  const [width, height] = useFrameSize();
  const [src, setSrc] = useState(
    require(`../../static/symbols/yellow/${paths[5]}`)
  );
  const [symbols, setSymbols] = useState([]);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const frame = useRef<ReturnType<typeof requestAnimationFrame> | null>(null);
  useEffect(() => {
    let count = 0;
    const animate = () => {
      count += 1;
      if (count % 2 === 0) {
        setSrc(symbols[_.random(16)]);
      }
      frame.current = requestAnimationFrame(animate);
    };
    if (symbols.length > 0) {
      frame.current = requestAnimationFrame(animate);
      const timeoutId = setTimeout(() => {
        setShouldRedirect(true);
      }, 2000);
      return () => {
        clearTimeout(timeoutId);
        cancelAnimationFrame(frame.current ?? 0);
      };
    }
  }, [symbols]);
  useEffect(() => {
    setSymbols(
      _.range(17).map((ind) =>
        require(`../../static/symbols/yellow/${paths[ind]}`)
      )
    );
  }, []);
  if (shouldRedirect) {
    let nextInd = _.random(59);
    while (nextInd === id) {
      nextInd = _.random(59);
    }
    return <Redirect to={`/neomix/${nextInd}`} />;
  }
  return (
    <>
      <Background />
      <div
        className="symbol-container"
        style={{
          position: "fixed",
          left: "25%",
          top: "25%",
          width: "50%",
          height: "50%",
          mixBlendMode: "exclusion",
        }}
      >
        <img
          src={src}
          alt="symbol"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
            objectPosition: "center center",
          }}
        />
      </div>
      <div
        className="footer"
        style={{
          position: "fixed",
          bottom: width < height ? "2vh" : "1.5%",
          width: "100%",
          left: 0,
          mixBlendMode: "exclusion",
          fontSize: "2.5vh",
        }}
      >
        <p
          style={{
            textAlign: "center",
            color: "#FFFF00",
            wordBreak: "keep-all",
          }}
        >
          랜덤 문구 생성 중...
        </p>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Loading);
