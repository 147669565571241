import React, { CSSProperties } from "react";

import { IDeviceProps } from "../../types";

const Artists = ({ isVertical }: IDeviceProps) => {
  const style: CSSProperties = {
    position: "relative",
    display: "inline-block",
    wordBreak: "keep-all",
    width: isVertical ? "calc(100% - 4vh)" : "calc(22%)",
    padding: isVertical ? "0 2vh" : "0 1.5%",
    verticalAlign: "top",
  };
  return (
    <div className="artists-container" style={style}>
      <p>고경아</p>

      <p style={{ paddingLeft: "1em", textIndent: "-1em" }}>김동관(한글씨)</p>

      <p>김리원</p>

      <p>김양진</p>

      <p>김태룡</p>

      <p>노은유</p>

      <p>박한솔</p>

      <p>신건모</p>

      <p style={{ paddingLeft: "1em", textIndent: "-1em" }}>
        오래오
        <wbr />
        스튜디오
      </p>

      <p>유형진</p>

      <p>이노을 ×</p>

      <p style={{ marginLeft: "1em" }}>로리스 올리비에</p>

      <p>이주현</p>

      <p>정지혜</p>

      <p>진유성</p>

      <p>채희준</p>

      <p>하형원</p>

      <p>함민주</p>
    </div>
  );
};

export default Artists;
