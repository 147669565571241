import React, { CSSProperties, useEffect, useState } from "react";
import { connect } from "react-redux";
import Background from "../common/Background";
import store from "../../redux/store";
import { updateHeader } from "../../redux/actions";
import useFrameSize from "../../hooks/useFrameSize";

import Info from "./Info";
import svg from "../../static/images/title.svg";
import alt from "../../static/images/semititle.svg";

const aspectRatio: number = 2000 / 1663;

export const Title = ({ onClick, showTitle }) => {
  useEffect(() => {
    store.dispatch(updateHeader([]));
  }, []);
  const [width, height] = useFrameSize();

  const [showSemititle, setShowSemititle] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setShowSemititle((state) => !state);
    }, 1900);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const h = height * 0.8;
  const frameRatio = width / h;

  const baseStyle: CSSProperties = {
    position: "fixed",

    zIndex: 998,

    margin: "3vh",
    mixBlendMode: "exclusion",
  };

  const titleContainerStyle: CSSProperties =
    frameRatio > aspectRatio
      ? {
          // frame width is bigger
          ...baseStyle,
          top: `10%`,
          bottom: `10%`,
          width: `${height * aspectRatio}px`,
          left: "calc(50% - 3vh)",
          transform: "translate(-50%)",
        }
      : {
          // frame height is bigger
          ...baseStyle,
          left: 0,
          right: 0,
          height: `${width / aspectRatio}px`,
          top: "calc(50% - 3vh)",
          transform: "translateY(-50%)",
        };

  const titleStyle: CSSProperties = {
    position: "absolute",
    height: "100%",
    width: "100%",
    objectFit: "contain",

    objectPosition: "center center",

    zIndex: 25,
  };
  return (
    <div
      className="title-container"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        margin: 0,
        width: "100%",
        height: "100%",
        zIndex: 997,
        opacity: showTitle ? 1 : 0,
        transition: "opacity 400ms ease-in-out",
        pointerEvents: showTitle ? undefined : "none",
      }}
      onClick={onClick}
    >
      <Background />
      <Info infoHeight={0.1} />
      <div className="title-container" style={titleContainerStyle}>
        <img
          className="title-image"
          src={svg}
          alt="사회가 잠시 멀어졌을 때"
          style={{
            ...titleStyle,
            opacity: showSemititle ? 0 : 1,
            transition: "opacity 400ms ease-in-out",
          }}
        />
        <img
          className="semititle-image"
          src={alt}
          alt="사회가 잠시 멀어졌을 때"
          style={{
            ...titleStyle,
            opacity: showSemititle ? 1 : 0,
            transition: "opacity 400ms ease-in-out",
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Title);
