import { useEffect, useRef, useState, RefObject } from "react";

const useHasScroll = <T extends HTMLElement>(): [RefObject<T>, boolean] => {
  const ref = useRef<T>(null);
  const [hasScroll, setHasScroll] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!ref.current) {
        setHasScroll(false);
      } else {
        setHasScroll(ref.current.scrollHeight > window.innerHeight);
      }
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return [ref, hasScroll];
};

export default useHasScroll;
