import React, { CSSProperties, useState, useEffect } from "react";
import { connect } from "react-redux";
import store from "../../redux/store";
import { updateHeader, changeBurgerColor } from "../../redux/actions";
import { IState, BURGER_COLOR } from "../../types";

import useFrameSize from "../../hooks/useFrameSize";
import useThemeColor from "../../hooks/useThemeColor";

export const Info = ({ open }) => {
  const [width, height] = useFrameSize();
  const [isVertical, setIsVertical] = useState(width < height);

  useEffect(() => {
    setIsVertical(width < height);
  }, [width, height]);

  useEffect(() => {
    if (!open) {
      store.dispatch(
        updateHeader([
          isVertical
            ? "네오-트라이브2020"
            : "네오-트라이브2020: 사회가 잠시 멀어졌을 때",
        ])
      );
      store.dispatch(changeBurgerColor(BURGER_COLOR.WHITE));
    }
  }, [open, isVertical]);

  useThemeColor("#4400CC");

  return (
    <div
      className="info-container"
      style={{ ...style, fontSize: `${width < height ? 3 : 5}vh` }}
    >
      <div
        className="info-text"
        style={{
          padding: `3vh 5vh 3vh ${width < height ? 2 : 3}vh`,
          width: `calc(100% - ${width < height ? 7 : 8}vh)`,
        }}
      >
        <p style={{ marginLeft: "2.5em" }}>
          네오-트라이브2020
          <br />
          온라인 글꼴 전시 관람 안내
        </p>
        <br />
        <p>
          작가로 참여한 17인의 글꼴 디자이너는 2020년에 태어난 새로운 부족,
          네오-트라이브2020입니다. 작가는 각 부족의 메신저가 되어 글꼴 작품으로
          그들의 메시지를 전달합니다. 아래와 같이 전시를 관람하고
          네오-트라이브의 새로운 일상에 참여해보세요.
        </p>
        <br />
        <div className="numbering-conatainer" style={{ position: "relative" }}>
          <p
            style={{
              display: "inline-block",
              verticalAlign: "top",
              margin: 0,
              padding: 0,
              width: "2.5em",
            }}
          >
            1
          </p>
          <p
            style={{
              display: "inline-block",
              verticalAlign: "top",
              margin: 0,
              padding: 0,
              width: "calc(100% - 2.5em)",
            }}
          >
            첫 페이지에서 {"\u2665"}, ; 등의 기호를 클릭하면 개별 작가의
            전시실로 이동합니다. 기호만으로 작가와 작품을 추리해 보세요.
          </p>
        </div>

        <div className="numbering-conatainer" style={{ position: "relative" }}>
          <p
            style={{
              display: "inline-block",
              verticalAlign: "top",
              margin: 0,
              padding: 0,
              width: "2.5em",
            }}
          >
            2
          </p>
          <p
            style={{
              display: "inline-block",
              verticalAlign: "top",
              margin: 0,
              padding: 0,
              width: "calc(100% - 2.5em)",
            }}
          >
            오른쪽 상단 메뉴를 통해 전시 소개 및 개별 작가의 전시실로 이동할 수
            있습니다.
          </p>
        </div>

        <div className="numbering-conatainer" style={{ position: "relative" }}>
          <p
            style={{
              display: "inline-block",
              verticalAlign: "top",
              margin: 0,
              padding: 0,
              width: "2.5em",
            }}
          >
            3
          </p>
          <p
            style={{
              display: "inline-block",
              verticalAlign: "top",
              margin: 0,
              padding: 0,
              width: "calc(100% - 2.5em)",
            }}
          >
            네오-믹스 메뉴에서는 랜덤 문구를 생성하고 이미지로 저장, 공유할 수
            있습니다. 기념품을 챙겨 가세요!
          </p>
        </div>
      </div>
    </div>
  );
};

const style: CSSProperties = {
  backgroundColor: "#4400cc",

  top: 0,

  left: 0,

  width: "100%",
  zIndex: 10,

  color: "white",

  wordBreak: "keep-all",

  paddingTop: "12vh",
};

const mapStateToProps = (state: IState) => ({
  open: state.burgerOpen,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Info);
