import React, { CSSProperties, useState, useEffect } from "react";

import useFrameSize from "../../hooks/useFrameSize";

interface ILandingInfoProps {
  infoHeight: number;
}

const Info = ({ infoHeight }: ILandingInfoProps) => {
  const [showClick, setShowClick] = useState(false);

  useEffect(() => {
    const timeoutId = setInterval(() => {
      setShowClick((showClick) => !showClick);
    }, 1900);

    return () => {
      clearInterval(timeoutId);
    };
  }, []);

  const [width, height] = useFrameSize();
  const baseStyle: CSSProperties = {
    position: "fixed",
    height: `${infoHeight * 100}%`,

    left: 0,
    right: 0,
    width: "100%",

    fontFamily: "Sandoll Greta Sans Beta",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: height > width ? "3vh" : "4.5vh",

    color: "yellow",
    textAlign: "center",

    display: "table",

    zIndex: 998,
    mixBlendMode: "exclusion",
  };

  const headerStyle: CSSProperties = {
    ...baseStyle,
    top: 0,
  };
  const footerStyle: CSSProperties = {
    ...baseStyle,
    bottom: 0,
  };

  return (
    <div className="info">
      <div className="info-header" style={headerStyle}>
        <p
          style={{
            display: "table-cell",
            verticalAlign: "middle",
          }}
        >
          2020.5.11–24
        </p>
      </div>
      <div
        className="info-footer"
        style={{
          ...footerStyle,
          transition: "opacity 400ms ease-in-out",
          opacity: showClick ? 0 : 1,
        }}
      >
        <p style={{ display: "table-cell", verticalAlign: "middle" }}>
          neotribe2020.xyz
        </p>
      </div>
      <div
        className="info-footer"
        style={{
          ...footerStyle,
          transition: "opacity 400ms ease-in-out",
          opacity: showClick ? 1 : 0,
        }}
      >
        <p style={{ display: "table-cell", verticalAlign: "middle" }}>
          화면을 클릭하세요!
        </p>
      </div>
    </div>
  );
};

export default Info;
