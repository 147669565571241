export const OPEN_BURGER = "OPEN_BURGER";
export const CLOSE_BURGER = "CLOSE_BURGER";

export const CHANGE_BURGER_COLOR = "CHANGE_BURGER_COLOR";

export const UPDATE_HEADER = "UPDATE_HEADER";
export const RESTORE_HEADER = "RESTORE_HEADER";

export const SHOW_BACK = "SHOW_BACK";
export const SHOW_INFO = "SHOW_INFO";
