import React from "react";
import { connect } from "react-redux";

import { IArtistProps } from "../../../types";

import Web from "./Web";
import Instagram from "./Instagram";
import Marketplace from "./Marketplace";

export const Links = ({ links, symbol, blue, id }: IArtistProps) => {
  const { web, instagram, marketplace } = links;

  return (
    <div
      className="link-container"
      style={{
        paddingLeft: "1.5em",
        paddingTop: "1.5em",
        wordBreak: "keep-all",
      }}
    >
      {web ? <Web links={links} symbol={symbol} blue={blue} /> : null}
      {instagram ? (
        <Instagram links={links} symbol={symbol} blue={blue} id={id} />
      ) : null}
      {marketplace ? (
        <Marketplace links={links} symbol={symbol} blue={blue} id={id} />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Links);
