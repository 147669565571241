import { CSSProperties } from "react";
export interface IBackgroundProps {
  x: number;
}

export interface IDeviceProps {
  isVertical: boolean;
}

export interface IBurgerProps {
  open?: boolean;
  color?: BURGER_COLOR;
  style?: CSSProperties;
  openBurger?(): void;
  closeBurger?(): void;
  showBurger?: boolean;
}

export interface IIndividualProps {}

export interface IAboutProps {
  ratio: number;
}

export interface IScreenPoint {
  x: number;
  y: number;
}

export interface marketplace {
  name: string;
  link: string;
  desc: string;
}
export interface instagram {
  link: string;
  desc?: string;
}

export interface ILookup {
  name: string;
  symbol: string;
  title: string;
  artwork: string;
  profile: string;
  caption: string;
  hover: number;
  links: {
    marketplace?: marketplace[];
    web?: string;
    instagram?: instagram[];
  };
}

export type IArtistProps = Pick<ILookup, "links" | "symbol"> & {
  blue: boolean;
  id?: number;
};

export interface IAction {
  type: string;
  payload?: any;
}

export enum BURGER_COLOR {
  BLUE = "blue",
  YELLOW = "yellow",
  WHITE = "white",
}
export interface ICustomLinkProps {
  style?: CSSProperties;
  name: string;
  body: string;
  href?: string;
  onClick?(): void;
  color: BURGER_COLOR;
  type: LinkClass;
}

export enum LinkClass {
  DIV = "div",
  SPAN = "span",
  A = "a",
  LINK = "link",
  DOWNLOAD = "download",
}

export interface IState {
  burgerOpen: boolean;
  burgerColor: BURGER_COLOR;
  headerContents: string[];
  prevContents: string[];
  showBack: boolean;
  showInfo: boolean;
}

export interface IHeaderProps {
  open: boolean;
  color: BURGER_COLOR;
  payload: string[];
  showArrow: boolean;
  showInfo: boolean;
  openBurger?(): void;
  closeBurger?(): void;
}
