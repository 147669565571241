import React from "react";
import { connect } from "react-redux";

import { ILookup } from "../../types";

export const Artwork = ({ artwork }: Partial<ILookup>) => {
  const src = require(`../../static/artworks/${artwork}`);
  //const download = useRef<HTMLAnchorElement>(null);

  return (
    <>
      <img
        className="artwork"
        src={src}
        alt={artwork}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
          objectPosition: "center center",
        }}
      />
      {/*
      <a
        className="download-test"
        style={{
          position: "fixed",
          margin: "3vh",
          bottom: 0,
          right: 0,
          width: "4vh",
          height: "4vh",
          background: "black",
        }}
        href={src}
        download
      >
        {" "}
      </a>
      <div
        className="dummy-button"
        style={{
          position: "fixed",
          margin: "3vh",
          bottom: 0,
          left: 0,
          width: "4vh",
          height: "4vh",
          background: "red",
        }}
        onClick={() => {
          console.log("red clicked");
          download.current?.click();
        }}
      ></div>
      <div
        className="fb-share-button"
        data-href="http://neotribe2020.xyz"
        data-layout="button"
        data-size="small"
        style={{
          top: -100000,
        }}
      >
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Fneotribe2020.xyz%2F&amp;src=sdkpreparse"
          className="fb-xfbml-parse-ignore"
          ref={download}
        >
          Share
        </a>
      </div>
      */}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Artwork);
