import React, { CSSProperties } from "react";

import { IDeviceProps, BURGER_COLOR, LinkClass } from "../../types";

import CustomLink from "../common/CustomLink";

const Leedotype = ({ isVertical }: IDeviceProps) => {
  const style: CSSProperties = {
    position: "relative",
    display: "inline-block",
    wordBreak: "keep-all",
    width: isVertical ? "calc(100% - 4vh)" : "calc(22%)",
    padding: isVertical ? "0 2vh" : "0 1.5%",
    verticalAlign: "top",
  };
  const instaLink = (id: string, body: string) => (
    <CustomLink
      name={`${id}-instagram`}
      href={`https://instagram.com/${id}`}
      color={BURGER_COLOR.WHITE}
      body={body}
      type={LinkClass.A}
    />
  );
  const leedotype = instaLink("leedotype", "이도타입");
  const hwoongkang = instaLink("hwoongkang", "강현웅");
  const dohee = instaLink("lee.do.hee", "이도희");
  const yeonjuchu = instaLink("yeonjuchu", "유연주");

  const paddingLeft: CSSProperties = {
    paddingLeft: "1em",
    textIndent: "-1em",
  };

  return (
    <div className="leedotype-container" style={style}>
      <p>주관</p>
      <p>{leedotype}</p>
      <br />
      <p>주최</p>
      <p>{leedotype}</p>
      <br />
      <p>기획</p>
      <p>{leedotype}</p>
      <br />
      <p style={paddingLeft}>타이틀 디자인</p>
      <p>{dohee}</p>
      <br />
      <p style={paddingLeft}>웹사이트 개발</p>
      <p>{hwoongkang}</p>
      <br />
      <p style={paddingLeft}>웹사이트 디자인</p>
      <p>{yeonjuchu}</p>
      <br />
    </div>
  );
};

export default Leedotype;
