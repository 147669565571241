import React, { CSSProperties } from "react";
import useFrameSize from "../../hooks/useFrameSize";

const Background = ({ blue }) => {
  const [width, height] = useFrameSize();
  const style: CSSProperties = {
    backgroundColor: blue ? "#4400CC" : "white",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    position: "fixed",
    top: 0,
    height: `${height}px`,
    left: 0,
    width: `${width}px`,
    zIndex: -999,
    transition: "all 300ms ease-in-out",
  };
  return <div className="background-white" style={style}></div>;
};

export default Background;
