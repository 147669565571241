import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { IState, BURGER_COLOR } from "../../types";

interface IProps {
  color: BURGER_COLOR;
  show: boolean;
}

export const HeaderGradient = ({ color, show }: IProps) => {
  const [isNull, setIsNull] = useState(true);
  const [white, setWhite] = useState(true);
  useEffect(() => {
    switch (color) {
      case BURGER_COLOR.YELLOW:
        setIsNull(true);
        break;
      case BURGER_COLOR.WHITE:
        setWhite(true);
        setIsNull(false);
        break;
      case BURGER_COLOR.BLUE:
        setWhite(false);
        setIsNull(false);
        break;
    }
  }, [color]);

  return isNull ? null : (
    <div
      className="header-gradient"
      style={{
        top: 0,
        left: 0,
        width: "100%",
        height: "12%",
        position: "fixed",
        background: `linear-gradient(${white ? "#4400CC" : "#FFFFFF"}, ${
          white ? "#4400CC" : "#FFFFFF"
        } 70%, rgba(${white ? "44,0,204" : "255,255,255"},0))`,
        zIndex: 299,
        opacity: show ? 1 : 0,
        transition: "opacity 300ms ease-in-out, color 100ms ease-in-out",
        pointerEvents: "none",
      }}
    ></div>
  );
};

const mapStateToProps = (state: IState) => ({
  color: state.burgerColor,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderGradient);
