import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { openBurger, closeBurger } from "../../../redux/actions/";

import { IState, IBurgerProps } from "../../../types";

export const Burger = ({
  open,
  color,
  style,
  openBurger,
  closeBurger,
  showBurger,
}: IBurgerProps) => {
  const [src, setSrc] = useState(
    require("../../../static/symbols/yellow/hamburger.svg")
  );
  const [hover, setHover] = useState(
    require("../../../static/symbols/yellow/hamburger_hover.svg")
  );

  useEffect(() => {
    setSrc(
      require(`../../../static/symbols/${color}/${
        open ? "cross" : "hamburger"
      }.svg`)
    );
    setHover(
      require(`../../../static/symbols/${color}/${
        open ? "cross" : "hamburger"
      }_hover.svg`)
    );
  }, [open, color]);
  return (
    <img
      className={`burger-button clickable-${color}`}
      src={showBurger ? src : hover}
      alt={color}
      style={{
        ...style,
        objectPosition: "center center",
        zIndex: 950,
      }}
    />
  );
};

const mapStateToProps = (state: IState) => ({
  open: state.burgerOpen,
  color: state.burgerColor,
});

export default connect(mapStateToProps, { openBurger, closeBurger })(Burger);
