import React, { CSSProperties, useState, useEffect } from "react";
import { BURGER_COLOR, ICustomLinkProps, LinkClass } from "../../types";
import { Link } from "react-router-dom";

const colorToHex = (color: BURGER_COLOR) => {
  switch (color) {
    case BURGER_COLOR.WHITE:
      return ["white", "#4400CC"];
    case BURGER_COLOR.BLUE:
      return ["#4400CC", "white"];
    case BURGER_COLOR.YELLOW:
      return ["#FFFF00", "black"];
  }
};

const CustomLink = ({
  style,
  name,
  body,
  href,
  color,
  type,
  onClick,
}: ICustomLinkProps) => {
  const [primary, setPrimary] = useState("white");
  const [secondary, setSecondary] = useState("#4400CC");
  const [localStyle, setLocalStyle] = useState<CSSProperties>({
    ...style,
    color: primary,
  });
  useEffect(() => {
    const [one, two] = colorToHex(color);
    setPrimary(one);
    setSecondary(two);
  }, [color]);

  useEffect(() => {
    setLocalStyle((state) => ({
      ...state,
      color: primary,
    }));
  }, [primary, secondary]);

  const toPrimaryStyle = () => {
    setLocalStyle((state) => ({
      ...state,
      textShadow: undefined,
      color: primary,
    }));
  };
  const toSecondaryStyle = () => {
    const hex = secondary;
    const outline = `-0.12vh -0.12vh 0 ${primary},
    0.12vh -0.12vh 0 ${primary},
    -0.12vh 0.12vh 0 ${primary},
    0.12vh 0.12vh 0 ${primary}`;
    setLocalStyle((state) => ({
      ...state,
      color: hex,
      textShadow: outline,
    }));
  };
  switch (type) {
    case LinkClass.A:
      return (
        <a
          className={`custom-link ${name}`}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          style={localStyle}
          onMouseEnter={toSecondaryStyle}
          onMouseLeave={toPrimaryStyle}
          onClick={() => {
            onClick?.();
            toPrimaryStyle();
          }}
        >
          {body}
        </a>
      );
    case LinkClass.SPAN:
      return (
        <span
          className={`toggle ${name}`}
          style={localStyle}
          onMouseEnter={toSecondaryStyle}
          onMouseLeave={toPrimaryStyle}
          onClick={() => {
            onClick?.();
            toPrimaryStyle();
          }}
        >
          {body}
        </span>
      );
    case LinkClass.LINK:
      return (
        <Link
          to={href ?? ""}
          className={`react-link ${name}`}
          style={localStyle}
          onMouseEnter={toSecondaryStyle}
          onMouseLeave={toPrimaryStyle}
          onClick={() => {
            onClick?.();
            toPrimaryStyle();
          }}
        >
          {body}
        </Link>
      );
    case LinkClass.DOWNLOAD:
      return (
        <a
          className={`custom-link ${name}`}
          href={href}
          style={localStyle}
          onMouseEnter={toSecondaryStyle}
          onMouseLeave={toPrimaryStyle}
          onClick={() => {
            onClick?.();
            toPrimaryStyle();
          }}
          download
        >
          {body}
        </a>
      );
    case LinkClass.DIV:
      return (
        <Link
          to={href ?? ""}
          className={`react-link ${name}`}
          style={localStyle}
          onMouseEnter={toSecondaryStyle}
          onMouseLeave={toPrimaryStyle}
          onClick={() => {
            onClick?.();
            toPrimaryStyle();
          }}
        >
          <p
            style={{ paddingLeft: "1em", textIndent: "-1em", lineHeight: 1.25 }}
          >
            {body
              .split("\u200B")
              .map((word) => <span>{word}</span>)
              .reduce((acc, now) => (
                <span>
                  {acc}
                  <wbr />
                  {now}
                </span>
              ))}
          </p>
        </Link>
      );
  }

  return (
    <a
      className={`custom-link ${name}`}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      style={localStyle}
      onMouseEnter={toSecondaryStyle}
      onMouseLeave={toPrimaryStyle}
    >
      {body}
    </a>
  );
};

export default CustomLink;
