import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { IState, IHeaderProps, BURGER_COLOR, LinkClass } from "../../types";

import Burger from "./burger/Burger";
import useFrameSize from "../../hooks/useFrameSize";

import HeaderGradient from "./HeaderGradient";
import InfoIcon from "./InfoIcon";
import BackIcon from "./BackIcon";

import { openBurger, closeBurger } from "../../redux/actions/";

import CustomLink from "./CustomLink";

export const Header = ({
  open,
  color,
  payload,
  showArrow,
  showInfo,
  openBurger,
  closeBurger,
}: IHeaderProps) => {
  const [width, height] = useFrameSize();
  const left = payload[0];
  const right = payload[1];
  const [showBurger, setShowBurger] = useState(true);
  const [showInfoHover, setShowInfoHover] = useState(false);
  const [showArrowHover, setShowArrowHover] = useState(false);
  const ref = useRef<HTMLAnchorElement>(null);
  const landingRef = useRef<HTMLAnchorElement>(null);

  return (
    <>
      <HeaderGradient show={left ? true : false} />
      <div
        className="header-container"
        style={{
          top: 0,
          left: 0,
          height: "6%",
          marginTop: height > width ? "2vh" : "1%",
          width: "100%",
          position: "fixed",
          zIndex: 500,
          mixBlendMode: color === BURGER_COLOR.YELLOW ? "exclusion" : undefined,
          color:
            color === BURGER_COLOR.WHITE
              ? "white"
              : color === BURGER_COLOR.BLUE
              ? "#4400CC"
              : "#FFFF00",
          transition: "color 100ms ease-in-out",
          lineHeight: 1.57,
          wordBreak: "keep-all",
        }}
      >
        <span
          className="left-payload"
          style={{
            position: "absolute",
            left: 0,

            paddingLeft: height > width ? "2vh" : "1.5%",
            fontSize: "3vh",
            opacity: left ? 1 : 0,
            transition: "opacity 300ms ease-in-out",
          }}
        >
          {left?.includes("네오-트라이브") ? (
            <CustomLink
              href="/"
              name="to-landing"
              type={LinkClass.LINK}
              body={left}
              color={color}
            />
          ) : (
            left
          )}
        </span>

        <span
          className="right-payload"
          style={{
            position: "absolute",
            left: "50%",
            width: "40%",
            fontSize: "3vh",
            opacity: right ? 1 : 0,
            transition: "opacity 300ms ease-in-out",
            marginLeft: "3vh",
          }}
        >
          {right}
        </span>

        <span
          className="burger-container"
          style={{
            marginTop: width < height ? "0.5vh" : "0.8%",
            position: "absolute",
            right: "2vh",
            width: "4vh",
            height: "4vh",
          }}
          onMouseEnter={() => setShowBurger(false)}
          onMouseLeave={() => setShowBurger(true)}
          onClick={open ? closeBurger : openBurger}
        >
          <Burger
            style={{
              marginTop: "0.1vh",
              marginLeft: "0.75vh",
              width: "2.5vh",
              height: "2.5vh",
            }}
            showBurger={showBurger}
          />
        </span>
        {showInfo ? (
          <>
            <a
              href="/howto"
              className="info-link"
              style={{ position: "fixed", top: -1000 }}
              ref={ref}
            >
              {" "}
            </a>
            <span
              className="info-container"
              style={{
                marginTop: width < height ? "0.5vh" : "0.8%",
                position: "absolute",
                right: "4.5vh",
                width: "4vh",
                height: "4vh",
                marginRight: "1.5vh",
              }}
              onClick={() => {
                ref.current?.click();
              }}
              onMouseEnter={() => setShowInfoHover(true)}
              onMouseLeave={() => setShowInfoHover(false)}
            >
              <InfoIcon
                style={{
                  marginLeft: "0.6vh",
                  width: "2.8vh",
                  height: "2.8vh",
                }}
                showBurger={showInfoHover}
              />
            </span>
          </>
        ) : null}
        {showArrow ? (
          <>
            <a
              href="/"
              className="landing-link"
              style={{ position: "fixed", top: -1500 }}
              ref={landingRef}
            >
              {" "}
            </a>
            <span
              className="back-container"
              style={{
                marginTop: width < height ? "0.5vh" : "0.8%",
                position: "absolute",
                right: "4.5vh",
                width: "4vh",
                height: "4vh",
                paddingRight: "1.5vh",
              }}
              onClick={() => {
                landingRef.current?.click();
              }}
              onMouseEnter={() => {
                setShowArrowHover(true);
              }}
              onMouseLeave={() => {
                setShowArrowHover(false);
              }}
            >
              <BackIcon
                style={{
                  marginTop: "0.1vh",
                  marginLeft: "0.75vh",
                  width: "2.5vh",
                  height: "2.5vh",
                }}
                showBurger={showArrowHover}
              />
            </span>
          </>
        ) : null}
      </div>
    </>
  );
};

Header.defaultProps = {
  showArrow: false,
};

const mapStateToProps = (state: IState) => ({
  open: state.burgerOpen,
  color: state.burgerColor,
  payload: state.headerContents,
  showArrow: state.showBack,
  showInfo: state.showInfo,
});

export default connect(mapStateToProps, { openBurger, closeBurger })(Header);
