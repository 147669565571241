import React from "react";
import { connect } from "react-redux";

import useFrameSize from "../../hooks/useFrameSize";
import CustomLink from "../common/CustomLink";
import { BURGER_COLOR, LinkClass } from "../../types";

export const Navigator = ({ id, blue }) => {
  const [width, height] = useFrameSize();
  //2vh
  return (
    <div
      className="navigator-container"
      style={{
        height: "3vh",
        margin: width < height ? "2vh" : "1.5%",
        width: `calc(100% - ${width < height ? "4vh" : "3%"})`,
        left: 0,
        fontSize: "2.5vh",
        color: blue ? "white" : "#4400CC",
      }}
    >
      <CustomLink
        href={`/artist/${Number(id) - 1 < 0 ? 16 : Number(id) - 1}`}
        name="to-prev"
        style={{
          position: "relative",
          left: 0,
          bottom: 0,
        }}
        color={blue ? BURGER_COLOR.WHITE : BURGER_COLOR.BLUE}
        type={LinkClass.LINK}
        body="이전 작가"
      />
      <CustomLink
        href={`/artist/${(Number(id) + 1) % 17}`}
        name="to-next"
        style={{
          position: "relative",
          left: 0,

          float: "right",
          bottom: 0,
        }}
        color={blue ? BURGER_COLOR.WHITE : BURGER_COLOR.BLUE}
        type={LinkClass.LINK}
        body="다음 작가"
      />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Navigator);
