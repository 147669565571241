import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { IState, IBurgerProps } from "../../types";

export const BackIcon = ({ open, color, style, showBurger }: IBurgerProps) => {
  const [src, setSrc] = useState(
    require("../../static/symbols/yellow/arrow.svg")
  );
  const [hover, setHover] = useState(
    require("../../static/symbols/yellow/arrow_hover.svg")
  );

  useEffect(() => {
    setSrc(require(`../../static/symbols/${color ?? "yellow"}/arrow.svg`));
    setHover(
      require(`../../static/symbols/${color ?? "yellow"}/arrow_hover.svg`)
    );
  }, [open, color]);
  return (
    <>
      <img
        className={`burger-button clickable-${color}`}
        src={showBurger ? hover : src}
        alt={color}
        style={{
          ...style,
          objectFit: "contain",
          objectPosition: "center center",
          zIndex: 950,
        }}
      />
    </>
  );
};

const mapStateToProps = (state: IState) => ({
  open: state.burgerOpen,
  color: state.burgerColor,
});

export default connect(mapStateToProps, {})(BackIcon);
