import { ILookup } from "../types";
const lookup: ILookup[] = [
  {
    name: "고경아",
    symbol: "kka.svg",
    artwork: "kka.jpg",
    title: "그럼에도 불구하고",
    profile: `디자인 에이전시에서 인쇄물 기반의 그래픽디자이너로 일했고 최근 인하우스 브랜딩디자이너로 새로운 업무를 시작했다. 2018년 8월부터 개인 작업으로 ‘길상체’를 그리고 있다. 2019년 전시 히읗 7회 〈글-하다〉에서 선보인 길상체는 상용화를 목표로 꾸준히 작업하고 있다.`,
    caption: `‘길상체’는 소설 『토지』 속 인물 ‘길상’에게 영감을 받아 시작한 서체로, 뭉툭한 획의 형태와 네모꼴에 가까운 꽉 찬 공간이 특징이다. 좁은 속공간과 맞닿은 획의 모습이 무게감을 더해 고전적이고 진중한 인상을 느낄 수 있다. 이번 전시에서 “그럼에도 불구하고 시간은 흐르고, 우리에게 중요한 것은 분명해진다”는 의미를 길상체의 세 가지 굵기 변화로 표현하였다.`,
    hover: 5,
    links: {
      instagram: [{ link: "https://instagram.com/kyeongahko" }],
    },
  },
  {
    name: "김동관",
    symbol: "kdk.svg",
    artwork: "kdk.png",
    title: "문 앞에 두고 가세요",
    profile: `2008년 성균관대학교 시각디자인 학과 졸업 후 2곳의 회사에서 폰트 제작 실무를 경험하였고, 2013년 독립하여 서체 디자인 스튜디오 한글씨를 만들었다. 이후 현재까지 8년간 ‘HG꼬딕씨’ 시리즈를 비롯하여 8개의 서체 패밀리를 디자인하였다. 지금도 계속 한글씨의 서체 패밀리를 차근차근 쌓아가고 있다.`,
    caption: `‘HG코스모스’라는 이름으로 곧 출시 예정인 서체이다. 직선적인 형태의 A 스타일과 곡선적인 형태의 B 스타일 중 B 스타일을 사용했다. 가로획과 세로획의 두께 대비를 반대로 적용한 reverse-contrast 개념이 이 디스플레이용 서체의 가장 큰 특징이다. HG코스모스 B는 단단하게 짜인 모듈 속에서 슬랩세리프의 요소를 약간 첨가하여 보다 풍부한 형태적 재미를 부여했다.`,
    hover: 5,
    links: {
      web: "http://hangulssi.com",
      instagram: [{ link: "https://instagram.com/hangulssi" }],
      marketplace: [
        {
          name: "산돌구름",
          link: "https://www.sandollcloud.com/font/Hangulssi.html",
          desc: "한글씨 라이선스 구매",
        },
        {
          name: "마켓히읗",
          link: "http://markethiut.com/product/list.html?cate_no=58",
          desc: "한글씨 라이선스 구매",
        },
      ],
    },
  },
  {
    name: "김리원",
    symbol: "klw.svg",
    artwork: "klw.jpg",
    title: "집콕중",
    profile: `RE01을 운영하는 그래픽 디자이너로, 현재 서울시립대학교 디자인전문 대학원에서 시각디자인 과정을 밟고 있다. 주로 글자와 그래픽의 형태에 관심을 가지고 인쇄 매체를 기반으로 한 그래픽, 편집, 전시 디자인과 그에 필요한 레터링 프로젝트를 진행한다.`,
    caption: `마치 이불 속에 웅크리고 있는 모습 같은 Ω는 ‘끝’의 의미를 지닌다. 이 부족은 집콕족으로, 쉬어갈 수 있게 된 시간을 만끽하기 위해 주로 이불 속에서 고개만 빼꼼 내밀고 있다. 이 서체는 획의 끝을 둥글게 마무리하여 몽글몽글한 느낌을 주고자 했고, 받침 ’ㅂ’과 ‘ㅇ’에서처럼 이불이 말린듯한 형태로 컨셉을 시각화했다. 획의 끊어짐과 이어짐, 기울기 변화를 통해 여유롭고 따뜻한 인상을 주고자 했다.`,
    hover: 3,
    links: { web: "https://re01.kr" },
  },
  {
    name: "김양진",
    symbol: "kyj.svg",
    artwork: "kyj.png",
    title: "모든것은 랜선으로",
    profile: `서울에서 직장을 다니면서 그래픽디자이너로 살고 있다. 살면서 드는 생각들을 하나씩 일러스트레이션이나 타이포그래피로 표현한다. 양진체라는 폰트를 만들어서 공개하기도 했고 이모티콘도 제작하였다. 요즘은 일러스트레이션과 타이포그래피를 적당히 섞어서 다양한 상품을 만드는 작업을 하고 있다.`,
    caption: `랜선은 세상을 연결한다. 전염병이 창궐하는 이 시국에도 우리는 랜선을 통해 많은 것들을 집 안에서 해결할 수 있다. 우리 부족에게 랜선은 신성한 것이다. 때문에 나는 고귀하고 성스러운 랜선을 상징화해 부족 내의 결속을 다지고 힘든 시기를 이겨나가고자 한다. 부족의 기호를 만들 때 집중한 부분은 랜선의 ‘커넥터’다. 커넥터는 랜선이 연결되는 기계에서 상당히 중요한 부분으로, 많은 것들이 무선으로 대체된 현대에서 필수불가결하게 남은 유선의 흔적이기도 한 것이다.`,
    hover: 5,
    links: {
      instagram: [{ link: "https://instagram.com/yangjin.c" }],
      marketplace: [
        {
          name: "링크트리",
          link: "https://linktr.ee/yangjin.c",
          desc: "양진체 및 관련 상품",
        },
      ],
    },
  },
  {
    name: "김태룡",
    symbol: "ktr.svg",
    artwork: "ktr.jpg",
    title: "다다다다다다다다다다다다다다다다음 주에 볼까요?",
    profile: `활자 및 그래픽 디자이너로, 디자인 스튜디오 비대칭과 정방형을 운영하고 있다. 2016년 ‘이면체’, 2018년 ‘산유화’ 등의 활자를 디자인했고 2020년 산작의 발표를 앞두고 있다. 단국대학교와 홍익대학교 대학원에서 시각디자인을 전공했다.`,
    caption: `‘각산’은 순명조 계열의 부리를 활용해 좁은 너비의 장체로 설계되었다. 단단하게 압축된 활자를 넉넉한 글자사이 공간을 두어 긴장감 있게 활용했다. 각각의 안전거리를 확보하고, 자신의 새로운 생활상을 모색하는 2020년의 모습을 떠올리며 그렸다.`,
    hover: 5,
    links: {
      instagram: [{ link: "https://instagram.com/taeryong.kim" }],
    },
  },
  {
    name: "노은유",
    symbol: "ney.svg",
    artwork: "ney.jpg",
    title: "평범한 일상의 소중함",
    profile: `글꼴 디자이너이자 연구자이다. 홍익대학교에서 ‘최정호의 한글꼴에 관한 연구’로 박사 학위를 받고 이를 바탕으로 『한글 디자이너 최정호』(안그라픽스, 2014)를 집필했다. AG타이포그라피연구소 책임연구원으로서 아모레퍼시픽의 ‘아리따체’, 대한불교조계종의 ‘석보체’등의 프로젝트를 맡아 진행했다. 네덜란드 헤이그 왕립미술학교에서 라틴 디자인을 수학하고 돌아와 옵티크 글꼴을 출시(2019)했다. 현재 노타입(NohType)을 운영 중이다.`,
    caption: `‘옵티크’는 한글과 라틴 문자를 위한 다국어 글꼴 디자인 프로젝트이다. 각 문자 고유의 쓰기 도구, 즉 한글은 붓, 라틴 문자는 넓은 펜촉(Broad Nib)을 바탕으로 디자인하여 서로 다른 두 문자의 인상을 조화롭게 만들었다. 옵티크는 시각적(Optical) 크기에 따라 본문용(Text)과 제목용(Display)으로 나누어 구성했다는 의미로 프랑스어 옵티크(Optique)에서 따 왔다. 이 작품은 옵티크 글꼴을 활용하여 ‘평범한 일상의 소중함’이라는 문구를 적었다. 글자 사이를 떨어트리고 그 빈 곳을 선으로 채움으로써 사람 사이의 거리는 멀어졌지만 마음은 연결되어 있음을 표현했다.`,
    hover: 5,
    links: {
      instagram: [{ link: "https://instagram.com/nohtype" }],
      marketplace: [
        {
          name: "AG폰트",
          link: "https://agfont.com/producet/옵티크-디스플레이-optique-display",
          desc: "옵티크 라이선스 구매",
        },
      ],
    },
  },
  {
    name: "박한솔",
    symbol: "phs.svg",
    artwork: "phs.png",
    title: "마스크도 좋지만 우리 집 침대가 더 좋아요",
    profile: `조합형 폰트 ‘봄소리체’를 제작하였고, 한글 레터링을 기반으로 그래픽 작업을 주로 하고 있다. 현재는 본문용 폰트인 ‘숨체’를 만들고 있는 중이다.`,
    caption: `‘숨체’는 11pt를 기준으로 제작하고 있는 휴머니스트 산세리프 계열의 본문용 서체이다. 연필로 정성을 담아 천천히 눌러 쓴 모습을 획에 담아내었고, 바탕과 돋움 그 사이에 있는 글자를 그려보고 싶다는 고민에서 출발한 서체이다.`,
    hover: 7,
    links: {
      instagram: [{ link: "https://instagram.com/hansol_park.otf" }],
      marketplace: [
        {
          name: "마켓히읗",
          link:
            "http://markethiut.com/product/detail.html?product_no=289&cate_no=24&display_group=1",
          desc: "봄소리체 라이선스 구매",
        },
      ],
    },
  },
  {
    name: "신건모",
    symbol: "skm.svg",
    artwork: "skm.jpg",
    title: "오디오 및 비디오를 사용하여 참가",
    profile: `계원예술대학교에서 그래픽 디자인을 공부하였으며, 졸업 이후 프리랜서 디자이너로 작업해왔다. 글자와 글자를 다루는 것에 보통 이상의 관심을 가지고 있으며, 주로 종이를 작업 매체로 사용하고 있다. 2013년부터 온양민속박물관의 아이덴티티와 간행물들을 작업하였으며, 국립현대미술관, 서울문화재단, 건축평단 등 여러 문화기관 및 단체와 협업해왔다. 현재 채희준과 함께 포뮬러(Formula)라는 이름으로 활동하고 있다.`,
    caption: `고정폭 글꼴(monospaced font)은 각 글자가 동일한 양의 수평 공간을 차지하는 글꼴을 말한다. 로마자를 기준으로 고정폭 글꼴은 주로 타자기나 컴퓨터 코딩에 사용되는데, 글자의 폭을 비슷하게 맞추기 위해서 일반적인 가변폭 글꼴과는 다른 조형적 특징을 가진다. 위 작업은 고정폭 로만 글꼴에 대응하는 고정폭 한글 글꼴로, 한글 타자기에 사용된 글자의 모양을 바탕으로 작업하였다. 단어와 단어 사이의 간격은 한글자가 들어갈 수 있을만큼 충분한 거리를 두고, 단락 기호(pilcrow)를 표기하여 단락 간의 구분을 확실히 한다.`,
    hover: 5,
    links: {
      web: "http://formula-studio.kr",
    },
  },
  {
    name: "오래오\u200B스튜디오",
    symbol: "os.svg",
    artwork: "os.jpg",
    title: "곡선을 평평하게",
    profile: `오래오스튜디오는 강민경, 김가영, 박계현, 정예슬이 운영하는 그래픽 디자인 스튜디오로, 디자인의 본질과 역할에 대해 고민하며 사람과 사회에 필요한 시각적 소통을 돕는 디자인을 지향한다. ‘서울나래학교’ 교내 사인물 아이덴티티, 컨셉코리아 10주년 기념 아카이빙 북 제작, 그룹 ‘이날치’ 비주얼 아트디렉팅 등 다양한 분야에서 활동하고 있다.`,
    caption: `이원모 활자체를 재해석한 서체로, 반듯한 직선의 뼈대와 정방향 네모 꼴의 순명조 글자이다. 힘주어 천천히 쓴 듯 반듯한 글자 모습으로 연출하고자 하였으며 부드럽고 강인한 표정을 담아냈다.`,
    hover: 5,
    links: {
      instagram: [
        { link: "https://instagram.com/oreohstudio", desc: "오래오스튜디오" },
        {
          link: "https://instagram.com/oreohstudio_fontlab",
          desc: "오래오스튜디오 타입 & 레터링",
        },
      ],
      web: "http://oreohstudio.com",
    },
  },
  {
    name: "유형진",
    symbol: "yhj.svg",
    artwork: "yhj.png",
    title: "앞으로의 삶을 준비할 때",
    profile: `글꼴을 그리는 프리랜서 타입 디자이너. 2018년까지 윤디자인그룹에서 브랜드 전용서체를 디자인 했고, 현재는 독립 디자이너로 활동하고 있다.`,
    caption: `“코로나19 이전의 세상은 다시 오지 않는다”고 하듯이 이전의 생활 그대로 돌아가는 건 어려운 일이 되었다. 이런 상황들이 심적으로 너무 무겁게 느껴지기도 해서, 앞으로 달라질 생활은 긍정적으로 다가오길 바랐다. 이에 미래를 위한 체크리스트를 쓴다고 생각하면서 명랑한 이미지의 글자를 그렸다.`,
    hover: 6,
    links: {
      instagram: [{ link: "https://instagram.com/typejinn_" }],
    },
  },
  {
    name: "이노을 \u200B× 로리스 \u200B올리비에",
    symbol: "lne.svg",
    artwork: "lne.png",
    title: "우리 22세기에 다시 만나요",
    profile: `글꼴디자이너이자 그래픽 디자이너. 국민대학교 대학원 커뮤니케이션 디자인 석사과정과 네덜란드 헤이그 왕립 예술학교 타입미디어(TypeMedia) 석사과정을 졸업하고 현재 한글과 라틴 중심의 글꼴디자이너로 활동하고 있다. 제6회 방일영문화재단 글꼴창작지원사업 수혜자로 선정되었으며, 2019년도 일본 모리사와 타입 공모전 라틴 카테고리에서 금상을 받았다. 2020년부터 스위스 디자이너 로리스 올리비에와 함께 글꼴 중심의 독립 스튜디오인 lo-ol foundry를 운영중이다.`,
    caption: `네오 그로테스크(neo-grotesk)적인 양상을 지닌 서체로 넓은 너비의 글자폭을 가지고 있다. 네모진 형태 안에서 세련되면서도 따뜻한 인상을 풍기는 방향으로 발전시켰다. 일반적인 구조에 좀 더 독특한 특징이 혼합된 이 서체는 스포티하면서도 강렬한 스타일을 지니고 있다.`,
    hover: 5,
    links: {
      instagram: [
        { link: "https://instagram.com/noheul_and_type", desc: "이노을" },
        { link: "https://instagram.com/maru_loris", desc: "로리스 올리비에" },
      ],
      web: "https://ty-index.design",
      marketplace: [
        {
          name: "이노을(퓨처폰트)",
          desc: "폰트 구매 사이트",
          link: "https://futurefonts.xyz/noheul-lee",
        },
        {
          name: "로리스 올리비에(퓨처폰트)",
          desc: "폰트 구매 사이트",
          link: "https://futurefonts.xyz/lo-ol",
        },
      ],
    },
  },
  {
    name: "이주현",
    symbol: "ljh.svg",
    artwork: "ljh.png",
    title: "미착용 시 출입 금지",
    profile: `서울에서 활동하고 있는 프리랜서 타입디자이너. 글꼴 관련한 물건들을 모으는 것을 좋아하며, 레터링 모임 ‘슭곰발’멤버로 활동하고 있다. 서울여자대학교 시각디자인학과를 졸업하고, 윤디자인에서 다수의 서체를 디자인했다.`,
    caption: `코로나 시대에 생필품으로 급부상한 물건을 꼽자면 단연 ‘마스크’다. 가벼운 천으로 만들어진 입과 코 가리개는 대면 활동 상황에서 새로운 매너와 규칙을 만들었다. 팬데믹 이후 편의점, 마트, 약국, 대중교통 등 공공영역 전반에서 “마스크 착용 필수” 임을 표시하며, 별도의 마스크 착용 공지가 없어도 이미 시대적 생활양식으로 자리 잡고 있다. 코로나 시대 이전에는 상상할 수 없이 낯선 이 생활양식을 표현하는, 이상하지만 진지하고 정중한 서체를 디자인했다.`,
    hover: 5,
    links: {
      instagram: [{ link: "https://instagram.com/type.eeu" }],
      marketplace: [
        {
          name: "",
          link: "http://naver.me/FzN3vU4o",
          desc: "슭곰발 레터링북 구매",
        },
      ],
    },
  },
  {
    name: "정지혜",
    symbol: "jjh.svg",
    artwork: "jjh.png",
    title: "방구석 꽃놀이",
    profile: `2019년에 꽃눈체를 출시했고, 현재 디스플레이타입 ‘고래실’을 그리고 있다. 글자기반의 그래픽 작업을 좋아하며 글자를 이용한 디자인 상품 제작에 관심이 많다. 서체를 만들면서 동시에 느리지만 꾸준히 그래픽 작업도 하고 있다.`,
    caption: `‘꽃눈체’는 탈네모꼴 구조로 명랑하고 밝은 느낌의 제목용 서체이다. 아르누보의 장식적인 요소와 한글 흘림체의 특징을 서체에 담았고, 전체적으로 따뜻하고 사랑스러운 인상을 주고자 했다.`,
    hover: 5,
    links: {
      instagram: [{ link: "https://instagram.com/0_to_back" }],
      marketplace: [
        {
          name: "마켓히읗",
          desc: "꽃눈체 라이선스 구매",
          link:
            "http://markethiut.com/product/detail.html?product_no=431&cate_no=24&display_group=1",
        },
      ],
    },
  },
  {
    name: "진유성",
    symbol: "jys.svg",
    artwork: "jys.jpg",
    title: "새로운 취미의 발견",
    profile: `건국대학교에서 만화의 전용서체와 관련된 논문을 통해 석사 학위를 취득하였다. 서체 전문회사 산돌커뮤니케이션에서 서체 디자이너로 근무하였고, 현재는 캘리그라피를 활용한 작업 및 폰트 제작을 병행하고 있다.`,
    caption: `이 서체는 붓펜으로 직접 쓴 글씨를 기반으로 만들어진 서체이다. 한글 2,350자를 쓰고 원도에서 나타나는 공통된 특징들을 반영해 작업하고 있다. 같은 자소일지라도 앞뒤 자소 배치에 따라 그 형태가 조금씩 달라지기 때문에 자연스러운 손글씨 느낌을 더해줄 수 있을 것으로 본다. 폰트라는 정리된 틀 안에 붓글씨의 자유로움이 숨 쉬는 서체가 되었으면 한다.`,
    hover: 6,
    links: { instagram: [{ link: "https://instagram.com/jin__yuseong" }] },
  },
  {
    name: "채희준",
    symbol: "chj.svg",
    artwork: "chj.png",
    title: "사회 적 거리두기",
    profile: `계원예술대학교에서 한글 타이포그래피를 전공했으며, 졸업 이후 글자 디자이너로 활동해 왔다. 새로움이나 트렌드를 추구하기보다는, 글자에서 느껴지는 감정과 형태의 상관관계를 생각하며, 미세한 차이를 감지하고 연구하는 과정을 통해 글자를 만든다. 2016년 ‘청월’을 출시하면서 주로 활자 제작에 집중하고 있으며, 이후 2017년에 ‘청조’, 2019년에 ‘초설’을 출시했다.`,
    caption: `‘초설’은 이름 그대로 첫눈 같은 글자를 만들고 싶어서 시작한 글꼴이다. 그래서 구조나 표현에 대한 고민 외에도 추상적인 감정을 이끌어내는 것에 많은 노력을 기울였으며, 최초에 상상했던 질감과 온도를 찾으려고 노력했다. 얼어붙을 정도로 추운 온도에서 펑펑 내리는 눈이 아닌, 적당히 차가운 온도에 ‘소복소복’ 보다 ‘사박사박’ 소리에 가까운 느낌에 도달하는 것이 관건이었다. 굵기는 ‘Light’, ‘Regular’, ‘Medium’ 세 가지로 작업 중이었으나 초설이 갖고 있는 형태적 특징들이 굵어질수록 부자연스럽다고 판단되어 ‘Medium’을 제외한 ‘Light’와 ‘Regular’ 두 종이 2019년에 출시되었다.`,
    hover: 5,
    links: {
      web: "http://formula-studio.kr",
      marketplace: [
        {
          name: "AG폰트",
          link: "https://agfont.com",
          desc: "폰트 라이선스 구매",
        },
        {
          name: "마켓히읗",
          link: "http://markethiut.com",
          desc: "폰트 라이선스 구매",
        },
        {
          name: "산돌구름",
          link: "https://sandollcloud.com",
          desc: "폰트 라이선스 구매",
        },
      ],
    },
  },
  {
    name: "하형원",
    symbol: "hhw.svg",
    artwork: "hhw.png",
    title: "모여봐요 화상미팅",
    profile: `하형원은 1인 그래픽 디자인 스튜디오를 운영한다. 글자 형태에 대한 관심을 바탕으로 타이틀 레터링을 만들고, 브랜딩 프로젝트를 진행한다. 서체 ‘됴웅’을 상용화했다.`,
    caption: `코로나 시대의 사람들은 현실에서 만나는 대신 게임에서 집들이를 하기도 하고, 재택근무 중 화상으로 업무를 처리하기도 한다. 비록 우리가 모이는 방식은 바뀌었지만, 여전히 모임은 즐겁고 한편으론 스마트하게 느껴진다. 여기에 어울리는 분명하고 밝은 인상을 지닌 고딕계열의 서체를 만들어 보았다.`,
    hover: 5,
    links: {
      web: "https://hyngwn.com",
      instagram: [{ link: "https://instagram.com/hahyeongwon" }],
      marketplace: [
        {
          name: "마켓히읗",
          desc: "됴웅 라이선스 구매",
          link:
            "http://markethiut.com/product/detail.html?product_no=256&cate_no=33&display_group=1",
        },
      ],
    },
  },
  {
    name: "함민주",
    symbol: "hmj.svg",
    artwork: "hmj.png",
    title: "힘내요",
    profile: `함민주는 현재 베를린을 기반으로 활동하고 있는 독립타입디자이너이다. 서울에서 시각디자인을 공부하고, 한국에서 타입디자이너로 약 6년간 근무한 후 라틴 타입디자인에 대해 더 배우고자 2015년 네덜란드 헤이그 왕립예술학교(KABK)에서 타입미디어(Type and Media) 석사과정을 이수했다. 그 후 독일 베를린에서 독립타입디자이너로 활동하며 국내외 기업들과 다국어 프로젝트를 주로 진행하고 있다.`,
    caption: `오노타입의 블레이즈페이스를 한글화한 작업이다. 획의 굵기대비가 강하고, 탐스러운 형태가 특징이다. 독특한 스타일의 제목용 글꼴로 레터링으로 오해를 받기도 한다.`,
    hover: 3,
    links: {
      web: "http://minjooham.com",
      marketplace: [
        {
          name: "퓨처폰트",
          desc: "폰트 라이선스 구매",
          link: "https://futurefonts.xyz/minjoo-ham",
        },
      ],
    },
  },
];

export default lookup;
