import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import reducer from "./reducer";
import { IState, BURGER_COLOR } from "../types";

const initialState: IState = {
  burgerOpen: false,
  burgerColor: BURGER_COLOR.YELLOW,
  headerContents: [],
  prevContents: [],
  showBack: false,
  showInfo: false,
};

const middleWare = [thunk];

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?? compose;

const store = createStore(
  reducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleWare))
);

export default store;
