import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { IState, ILookup, BURGER_COLOR } from "../../types";

import artists from "../../static/lookup";
import Artwork from "./Artwork";

import ColorBackground from "../common/ColorBackground";

import Links from "./links";

import useVisibility from "../../hooks/useVisibility";

import useFrameSize from "../../hooks/useFrameSize";
import { changeBurgerColor, updateHeader, showBack } from "../../redux/actions";
import store from "../../redux/store";

import Navigator from "./Navigator";
import useThemeColor from "../../hooks/useThemeColor";

export const Individual = ({ match, open }: any) => {
  const [width, height] = useFrameSize();
  const [isVertical, setIsVertical] = useState(false);

  useEffect(() => {
    setIsVertical(width < height);
  }, [width, height]);

  const id = match.params.id;

  const { name, title, symbol, artwork, profile, caption, links }: ILookup =
    artists[id ?? 0];

  const [profileRef, profileVisibility] = useVisibility<HTMLDivElement>();

  const [artworkRef, artworkVisibility] = useVisibility<HTMLDivElement>();

  const [blue, setBlue] = useState(false);

  useEffect(() => {
    if (artworkVisibility !== undefined) {
      setBlue(!artworkVisibility);
    }
  }, [artworkVisibility]);

  useThemeColor(blue ? "#4400CC" : "#FFF");

  useEffect(() => {
    store.dispatch(updateHeader(profileVisibility ? [name] : []));
  }, [profileVisibility, name, title]);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, [id]);

  useEffect(() => {
    if (!open) {
      store.dispatch(
        changeBurgerColor(blue ? BURGER_COLOR.WHITE : BURGER_COLOR.BLUE)
      );
    }
  }, [blue, open]);

  useEffect(() => {
    store.dispatch(showBack(true));

    return () => {
      store.dispatch(showBack(false));
    };
  }, []);

  return (
    <div
      className="artist-container"
      style={{
        fontSize: isVertical ? "3vh" : "3vh",

        color: blue ? "white" : "#4400CC",
        backgroundColor: blue ? "#4400CC" : "white",
        transition: "all 300ms ease-in-out",
        lineHeight: 1.57,
      }}
    >
      <ColorBackground blue={blue} />

      <div
        className="artwork-container"
        style={{
          position: "relative",
          margin: "2vh",
          paddingBottom: "5vh",
          top: 0,
          left: 0,
          width: `calc(${width}px - 4vh)`,
          height: `calc(${height}px - 4vh)`,
        }}
        ref={artworkRef}
      >
        <Artwork
          artwork={
            Number(id) !== 0 || width > height ? artwork : "portrait-kka.jpg"
          }
        />
      </div>
      <div
        className="left-container"
        style={{
          // depends on
          margin: isVertical ? "2vh 2vh 3vh 2vh" : "0 1.5% 3vh 1.5%",
          width: `calc(${isVertical ? "100%" : "50%"} - ${
            isVertical ? "4vh" : "3%"
          })`,

          // only on vertical
          top: isVertical ? 0 : undefined,
          verticalAlign: isVertical ? "top" : undefined,

          // only on landscape
          left: isVertical ? undefined : 0,

          height: isVertical ? "auto" : "78vh",

          // both
          position: "relative",
          wordBreak: "keep-all",

          display: "inline-block",
        }}
      >
        <div className="profile" ref={profileRef}>
          {profile.split("\n").map((paragraph, ind) => (
            <p key={ind}>{paragraph}</p>
          ))}
        </div>

        <Links links={links} symbol={symbol} blue={blue} id={id} />
      </div>

      <div
        className="right-container"
        style={{
          // depends on
          margin: isVertical ? "0 2vh 3vh 2vh" : "0 3vh 3vh 3vh",
          width: `calc(${isVertical ? "100%" : "50%"} - ${
            isVertical ? 4 : 6
          }vh)`,

          // only on vertical
          top: isVertical ? 0 : undefined,

          // only on landscape
          left: isVertical ? undefined : 0,
          verticalAlign: isVertical ? undefined : "top",

          // both
          position: "relative",
          wordBreak: "keep-all",

          display: "inline-block",

          height: isVertical ? "auto" : "78vh",
        }}
      >
        <div
          className="caption"
          style={{
            paddingTop: isVertical ? "15vw" : undefined,
            paddingBottom: isVertical ? "5vw" : undefined,
          }}
        >
          {caption.split("\n").map((paragraph, ind) => (
            <p key={ind}>{paragraph}</p>
          ))}
        </div>
      </div>
      <Navigator id={id} blue={blue} />
    </div>
  );
};

const mapStateToProps = (state: IState) => ({
  open: state.burgerOpen,
});

export default connect(mapStateToProps, {})(Individual);
