import {
  OPEN_BURGER,
  CLOSE_BURGER,
  CHANGE_BURGER_COLOR,
  UPDATE_HEADER,
  SHOW_BACK,
  SHOW_INFO,
} from "./types";
import { BURGER_COLOR } from "../../types";

export const openBurger = () => (dispatch) => {
  dispatch({ type: OPEN_BURGER });
  dispatch({ type: CHANGE_BURGER_COLOR, payload: BURGER_COLOR.WHITE });
  dispatch({ type: UPDATE_HEADER, payload: [] });
};

export const closeBurger = () => (dispatch) => {
  dispatch({ type: CLOSE_BURGER });
};

export const changeBurgerColor = (color: BURGER_COLOR) => (dispatch) => {
  dispatch({ type: CHANGE_BURGER_COLOR, payload: color });
};

export const updateHeader = (newContents: string[]) => (dispatch) => {
  dispatch({ type: UPDATE_HEADER, payload: newContents });
};

export const showBack = (show: boolean) => (dispatch) => {
  dispatch({ type: SHOW_BACK, payload: show });
};

export const showInfo = (show: boolean) => (dispatch) => {
  dispatch({ type: SHOW_INFO, payload: show });
};
