import React, { CSSProperties, useState, MouseEvent, TouchEvent } from "react";

import { BrowserRouter as Router, Route } from "react-router-dom";

import { Provider } from "react-redux";
import store from "./redux/store";

import "./App.css";

import Background from "./components/common/Background";
import Landing from "./components/landing";
import Header from "./components/common/Header";
import Menu from "./components/common/burger/Menu";
import About from "./components/about";
import Howto from "./components/howto";
import Artist from "./components/artists";
import Neomix from "./components/neomix";
import Credits from "./components/credits";
import useHasScroll from "./hooks/useHasScroll";
import Loading from "./components/neomix/Loading";
import { IScreenPoint } from "./types";

function App() {
  const [maskStyle, setMaskStyle] = useState<CSSProperties>(initialMaskStyle);
  const [appRef, appHasScroll] = useHasScroll<HTMLDivElement>();
  const [touch, setTouch] = useState<IScreenPoint>({ x: 0, y: 0 });

  const setMaskPosition = (x: number, y: number) => {
    const radius = 10;
    const background =
      "radial-gradient(farthest-side, #fff 50%, transparent 100%)" +
      `calc(${x}px - ${radius}em) calc(${y}px - ${radius}em)/${2 * radius}em ${
        2 * radius
      }em fixed no-repeat`;
    setMaskStyle((maskStyle) => ({
      ...maskStyle,
      background,
    }));
  };

  const handleMouseMove = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setMaskPosition(e.clientX, e.clientY);
  };
  const handleTouchMove = (e: TouchEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const touch = e.touches[0];

    appHasScroll
      ? setMaskPosition(touch.screenX, touch.screenY)
      : setMaskPosition(touch.clientX, touch.clientY);
    setTouch({
      x: touch.clientX - window.pageXOffset,
      y: touch.clientY - window.pageYOffset,
    });
    e.stopPropagation();
  };

  return (
    <Provider store={store}>
      <div
        className="App"
        onMouseMove={handleMouseMove}
        onTouchMove={handleTouchMove}
        ref={appRef}
      >
        <div className="mask" style={maskStyle} />

        <Router>
          <Header />

          <Menu />

          <Route exact path="/">
            <Background />
            <Landing touch={touch} />
          </Route>

          <Route exact path="/howto">
            <Howto />
          </Route>

          <Route exact path="/about">
            <About />
          </Route>

          <Route
            path="/artist/:id"
            render={(props) => <Artist match={props.match} />}
          />

          <Route exact path="/mix/loading">
            <Loading />
          </Route>
          <Route
            exact
            path="/neomix/:id?"
            render={(props) => <Neomix match={props.match} />}
          />

          <Route exact path="/credits">
            <Credits />
          </Route>
        </Router>
      </div>
    </Provider>
  );
}

const initialMaskStyle: CSSProperties = {
  color: "#fff",
  content: "",
  position: "fixed",
  zIndex: 999,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  mixBlendMode: "difference",
  pointerEvents: "none",
};

export default App;
