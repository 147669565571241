import { useEffect, useRef, useState, RefObject } from "react";

const useVisibility = <T extends HTMLElement>(): [
  RefObject<T>,
  boolean | undefined
] => {
  const ref = useRef<T>(null);
  const [visibility, setVisibility] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const handleScroll = () => {
      const rect = ref.current?.getBoundingClientRect();

      if (!rect) {
        setVisibility(undefined);
      } else {
        const { top, bottom, height } = rect;

        setVisibility(
          top < window.innerHeight - 0.1 * height && bottom > 0.1 * height
        );
      }
    };
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return [ref, visibility];
};

export default useVisibility;
