import React, { CSSProperties } from "react";
import { connect } from "react-redux";
import { IDeviceProps } from "../../types";

export const SponsoredBy = ({ isVertical }: IDeviceProps) => {
  const style: CSSProperties = {
    position: "relative",
    display: "inline-block",
    wordBreak: "keep-all",
    width: isVertical ? "calc(100% - 4vh)" : "100%",
    padding: isVertical ? "0 2vh" : "0",
    verticalAlign: "top",
  };
  return (
    <div className="sponsored-by" style={style}>
      <p>후원</p>
      <p>서울시 청년청</p>
      <p>산돌구름</p>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SponsoredBy);
