import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import store from "../../redux/store";
import { changeBurgerColor, updateHeader, showInfo } from "../../redux/actions";

import artists from "../../static/lookup";

import Tribe from "./Tribe";

import _ from "lodash";

import { IScreenPoint, IState, BURGER_COLOR } from "../../types";
import useFrameSize from "../../hooks/useFrameSize";

import Title from "./Title";
import useThemeColor from "../../hooks/useThemeColor";

const equalPoints: IScreenPoint[] = [
  { x: 16, y: 16 },
  { x: 39, y: 16 },
  { x: 62, y: 16 },
  { x: 85, y: 16 },
  { x: 16, y: 85 },
  { x: 39, y: 85 },
  { x: 62, y: 85 },
  { x: 85, y: 85 },
  { x: 16, y: 62 },
  { x: 39, y: 62 },
  { x: 62, y: 62 },
  { x: 85, y: 62 },
  { x: 16, y: 39 },
  { x: 39, y: 39 },
  { x: 62, y: 39 },
  { x: 85, y: 39 },
  { x: 50, y: 50 },
];

interface ILandingProps {
  open: boolean;
  touch?: IScreenPoint;
}

const Landing = ({ open, touch }: ILandingProps) => {
  const [width, height] = useFrameSize();
  const [showTitle, setShowTitle] = useState(true);

  useEffect(() => {
    if (open) {
    } else {
      store.dispatch(
        updateHeader(
          width < height
            ? ["네오-트라이브2020"]
            : ["네오-트라이브2020: 사회가 잠시 멀어졌을 때"]
        )
      );
      store.dispatch(changeBurgerColor(BURGER_COLOR.YELLOW));
    }
  }, [open, width, height]);

  useEffect(() => {
    store.dispatch(showInfo(true));
    return () => {
      store.dispatch(showInfo(false));
    };
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useThemeColor(open ? "#4400CC" : "#6D3082");

  const [shuffledPoints] = useState<IScreenPoint[]>(_.shuffle(equalPoints));

  return (
    <>
      <Title onClick={() => setShowTitle(false)} showTitle={showTitle} />;
      <div
        className="landing"
        style={{
          opacity: showTitle ? 0 : 1,
          transition: "opacity 500ms ease-in-out",
        }}
      >
        {artists.map((artist, ind) => (
          <Tribe
            pos={shuffledPoints[ind]}
            artist={artist}
            ind={ind}
            key={ind}
            touch={touch}
          />
        ))}
      </div>
    </>
  );
};

const mapStateToProps = (state: IState) => ({
  open: state.burgerOpen,
  color: state.burgerColor,
});

export default connect(mapStateToProps, {})(Landing);
