import React, { CSSProperties, useEffect } from "react";
import { connect } from "react-redux";

import CustomLink from "../CustomLink";

import { IState, IBurgerProps, LinkClass, BURGER_COLOR } from "../../../types";

import { closeBurger, updateHeader } from "../../../redux/actions";
import store from "../../../redux/store";

import artists from "../../../static/lookup";
import "../../../static/fonts/SDGretaSansKRjBlk.css";
import useFrameSize from "../../../hooks/useFrameSize";

export const Menu = ({ open }: IBurgerProps) => {
  const [width, height] = useFrameSize();

  useEffect(() => {
    if (open) {
      store.dispatch(
        updateHeader(
          width < height
            ? ["네오-트라이브2020"]
            : ["네오-트라이브2020: 사회가 잠시 멀어졌을 때"]
        )
      );
    }
  }, [open, width, height]);

  const style: CSSProperties = {
    backgroundColor: "#4400CC",
    position: "fixed",
    width: `calc(100% - ${width < height ? "4vh" : "1.5%"})`,
    height: `calc(100% - ${width < height ? 23 : 11}vw)`,

    top: 0,
    paddingLeft: `${width < height ? "2vh" : "1.5%"}`,
    paddingRight: `${width < height ? "2vh" : undefined}`,
    paddingTop: `${width < height ? 22 : 10}vw`,
    paddingBottom: "1vw",

    transform: `translateY(${open ? "0" : "-100.5%"})`,
    transition: "all 0.7s ease-in-out",
    overflow: "auto",

    fontSize: `${width < height ? 7 : 12}vh`,

    zIndex: 50,
    lineHeight: 1.25,

    display: "inline-block",
  };

  const dispatchClose = () => {
    store.dispatch(closeBurger());
  };

  return (
    <div className="burger-menu" style={style}>
      <CustomLink
        href="/"
        onClick={dispatchClose}
        name="to-landing"
        type={LinkClass.LINK}
        color={BURGER_COLOR.WHITE}
        body={"인트로"}
      />
      <br />
      <CustomLink
        href="/howto"
        onClick={dispatchClose}
        name="to-howto"
        type={LinkClass.LINK}
        color={BURGER_COLOR.WHITE}
        body={"관람 안내"}
      />
      <br />
      <CustomLink
        href="/about"
        onClick={dispatchClose}
        name="to-about"
        type={LinkClass.LINK}
        color={BURGER_COLOR.WHITE}
        body={"네오-트라이브2020"}
      />

      {artists.map((artist, ind) => (
        <div className="artists" key={ind} style={{ paddingLeft: "1.5em" }}>
          <CustomLink
            href={`/artist/${ind}`}
            onClick={dispatchClose}
            name="to-artists"
            type={LinkClass.DIV}
            color={BURGER_COLOR.WHITE}
            body={artist.name}
            style={{
              wordBreak: "keep-all",
              lineHeight: 1.25,
            }}
          />
        </div>
      ))}
      <br />
      <CustomLink
        href={`/neomix`}
        onClick={dispatchClose}
        name="to-neomix"
        type={LinkClass.LINK}
        color={BURGER_COLOR.WHITE}
        body="네오-믹스2020"
      />
      <br />
      <CustomLink
        href={`/credits`}
        onClick={dispatchClose}
        name="to-credits"
        type={LinkClass.LINK}
        color={BURGER_COLOR.WHITE}
        body="크레딧"
      />
    </div>
  );
};

const mapStateToProps = (state: IState) => ({
  open: state.burgerOpen,
});
export default connect(mapStateToProps, {})(Menu);
