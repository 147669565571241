import { IState, BURGER_COLOR } from "../types";
import {
  OPEN_BURGER,
  CLOSE_BURGER,
  CHANGE_BURGER_COLOR,
  UPDATE_HEADER,
  RESTORE_HEADER,
  SHOW_BACK,
  SHOW_INFO,
} from "./actions/types";

const initialState: IState = {
  burgerOpen: false,
  burgerColor: BURGER_COLOR.YELLOW,
  headerContents: [],
  prevContents: [],
  showBack: false,
  showInfo: false,
};
export default (state = initialState, action: any) => {
  switch (action.type) {
    case OPEN_BURGER:
      return {
        ...state,
        burgerOpen: true,
      };
    case CLOSE_BURGER:
      return {
        ...state,
        burgerOpen: false,
      };
    case CHANGE_BURGER_COLOR:
      return {
        ...state,
        burgerColor: action.payload,
      };
    case UPDATE_HEADER:
      return {
        ...state,
        prevContents: state.headerContents,
        headerContents: action.payload,
      };
    case RESTORE_HEADER:
      return {
        ...state,
        headerContents: state.prevContents,
      };
    case SHOW_BACK:
      return {
        ...state,
        showBack: action.payload,
      };
    case SHOW_INFO:
      return {
        ...state,
        showInfo: action.payload,
      };
    default:
      return state;
  }
};
